.leaflet-map__buttons {
  position: absolute;
  z-index: 10000;
  left: 10px;
  bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}

.leaflet-map__attribution {
  position: absolute;
  z-index: 10000;
  right: 0;
  bottom: 0;
  font-size: 0.75rem;
  color: #333;
  background-color: rgba(255, 255, 255, 0.8);
}

.leaflet-map__container {
  position: relative;
}

.leaflet-map__map-container {
  width: 100%;
  height: 100%;
}

.leaflet-map__center-button {
  position: absolute;
  z-index: 10000;
  right: 10px;
  bottom: 10px;
  line-height: 0;
  font-size: x-large;
}
