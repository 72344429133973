.viewable-image__backdrop {
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.viewable-image__img {
  max-width: 100%;
    max-height: 100vh;
    height: auto;
}

.viewable-image__title {
  background-color: white;
  padding: 1rem;
}
