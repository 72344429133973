.map-view__container {
  height: calc(100vh - 61px);
  width: 100vw;
  z-index: 0;
}
.map-view__button {
  line-height: 0;
  font-size: x-large;
}
.map-view__toast {
  position: absolute;
  z-index: 1;
  bottom: 25px !important;
}
